export const ValidStatus = [
    'PrivateClient:TransactionCompleted',
    'PrivateClient:RequestForApproval',
    'PrivateClient:Rejected',
    'PrivateClient:ErrorEncountered',
]

export const TransactionTypes = [
    'Deposit',
    'FX',
    'Payment Out',
    'Transfer',
    'Withdrawal',
    'Buy',
    'Sell',
    'Exchange',
    'Send',
    'Receive'
]

export const CryptoTransactionTypes = [
    'Buy',
    'Sell',
    'Exchange',
    'Send',
    'Receive'
]

export const CurrencyTypes = [
    "Fiat",
    "Crypto"
]
export const ErrorTransactions = [
    'PrivateClient:Rejected',
    'PrivateClient:ErrorEncountered',
    'BuyFlow:ErrorEncountered',
    'SellFlow:ErrorEncountered',
    'SendFlow:ErrorEncountered',
    'ReceiveFlow:ErrorEncountered',
    'ExchangeFlow:ErrorEncountered',
]

export const COLORS = ["#3CCB7F", "#A48AFB", "#FDB022", "#22CCEE", "#E478FA", "#D0D5DD"];

export const erc20Tokens = ['ETH', 'DAI', 'USDT', 'USDC'];
